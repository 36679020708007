let idleTime = 0;
const maxIdleTime = 30; // in seconds
let timer;
let remainingTime = maxIdleTime;

const resetIdleTimer = () => {
  idleTime = 0;
  remainingTime = maxIdleTime;
};

const startIdleTimer = (callback) => {
 // console.log('Idle timer started');
  document.addEventListener('mousemove', resetIdleTimer);
  document.addEventListener('keypress', resetIdleTimer);
  document.addEventListener('mousedown', resetIdleTimer);
  document.addEventListener('touchstart', resetIdleTimer);

  timer = setInterval(() => {
    idleTime += 1;
    remainingTime -= 1;
    if (idleTime >= maxIdleTime) {
      callback();
      clearInterval(timer);
    }
	//console.log(remainingTime);
  }, 1000);
};

const stopIdleTimer = () => {
  document.removeEventListener('mousemove', resetIdleTimer);
  document.removeEventListener('keypress', resetIdleTimer);
  document.removeEventListener('mousedown', resetIdleTimer);
  document.removeEventListener('touchstart', resetIdleTimer);
  clearInterval(timer);
 // console.log('Idle timer stopped');
};

const getRemainingTime = () => remainingTime;

export { startIdleTimer, stopIdleTimer, getRemainingTime };
