<template>
  <div
    v-if="visible"
    class="numeric-keypad"
    :style="{ top: `${position.y}px`, left: `${position.x}px` }"
  >
    <button
      v-for="key in keys"
      :key="key"
      @click="emitKey(key)"
      class="keypad-button"
    >
      {{ key }}
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      keys: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9','CLR'],
      visible: false,
      position: { x: 0, y: 0 },
    };
  },
  methods: {
    show(x, y) {
      this.position = { x, y };
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
    emitKey(key) {
      this.$emit('key-pressed', key);
    },
  },
};
</script>

<style scoped>
.numeric-keypad {
  position: absolute;
  z-index: 1000; /* Ensures the keypad is above other content */
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      margin: 5px;
    border: 4px solid black;
}
.keypad-button {
  width: 60px;
  height: 60px;
  margin: 5px;
  font-size: 24px;
  cursor: pointer;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

}
</style>
