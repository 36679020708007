<!-- eslint-disable -->
<template>
  <div id="container" class="hfeed wpbf-page"  >
    <loader
      object="#ff9633"
      v-if="isLoading"
      color1="#ffffff"
      color2="#999793"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="80"
      name="spinning"
    ></loader>
    <div
      data-elementor-type="wp-page"
      data-elementor-id="814"
      class="elementor elementor-814"
      data-elementor-settings="[]"
    >
      <div class="elementor-section-wrap">
        <!--START of BACKGROUND SLIDER-->

        <!--END of BACKGROUND SLIDER-->
        <section
          class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-section-content-middle elementor-section-height-full elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
          data-particle_enable="false"
          data-particle-mobile-disabled="false"
          data-id="8734d79"
          data-element_type="section"
          data-settings='{"stretch_section":"section-stretched"}'
        >
          <div class="elementor-background-overlay"></div>
          <div class="elementor-container elementor-column-gap-no" style="margin-top:-60px">
            <div
              class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ff8178f"
              data-id="ff8178f"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                 <h3 v-show="error" style="color:red; background-color:white;padding:50px">Sorry Somthing Wrong in your request</h3>

                <section  v-show="!error"
                  class="has_eae_slider elementor-section elementor-inner-section elementor-element elementor-element-6140ba3 elementor-section-content-middle elementor-section-height-min-height elementor-section-boxed elementor-section-height-default"
                  data-particle_enable="false"
                  data-particle-mobile-disabled="false"
                  data-id="6140ba3"
                  data-element_type="section"
                  
                >
                  <div class="elementor-background-overlay"></div>
                  <div   class="elementor-container elementor-column-gap-no">
                    <div
                      class="has_eae_slider elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-52284d9"
                      data-id="52284d9"
                      data-element_type="column"
                      
                    >
                      <div
                        class="elementor-widget-wrap elementor-element-populated"
                      >
                        <div
                          class="elementor-element elementor-element-3d8b0c1 elementor-widget elementor-widget-image"
                          data-id="3d8b0c1"
                          data-element_type="widget"
                          data-widget_type="image.default"
                        >
                          <div class="elementor-widget-container">
                            <a>
                              <img
                                width="518"
                                height="268"
                                :src="hotelData.hotelLogo"
                                class="attachment-full size-full"
                                alt=""
                                loading="lazy"
                                 
                                sizes="(max-width: 518px) 100vw, 518px"
                              />
                            </a>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-c184235 elementor-widget elementor-widget-heading"
                          data-id="c184235"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h2
                              class="elementor-heading-title elementor-size-default"
                            >
							{{lang.login.guestLogin}}
                            </h2>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-32307f9 elementor-align-center elementor-widget__width-auto elementor-widget elementor-widget-bdt-advanced-button"
                          data-id="32307f9"
                          data-element_type="widget"
                          data-widget_type="bdt-advanced-button.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="bdt-ep-button-wrapper">
                              <router-link
                                :to="{ name: 'Home', params: {id: hotelId} }"
                               
                                class="bdt-ep-button bdt-ep-button-effect-a bdt-ep-button-size-md elementor-animation-push"
                              >
                                <div
                                  class="bdt-ep-button-content-wrapper bdt-flex bdt-flex-middle bdt-flex-center"
                                  data-text="Back Home"
                                >
                                  <div class="bdt-ep-button-text">
                                    <span class="avdbtn-text">{{lang.login.backHome}}</span>
                                  </div>
                                </div>
                              </router-link>
                            </div>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-204e083 elementor-align-center elementor-widget__width-auto elementor-widget elementor-widget-bdt-advanced-button"
                          data-id="204e083"
                          data-element_type="widget"
                          data-widget_type="bdt-advanced-button.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="bdt-ep-button-wrapper">
                              <!-- <router-link
                                to="book"
                                class="bdt-ep-button bdt-ep-button-effect-a bdt-ep-button-size-md elementor-animation-push"
                              >
                                <div
                                  class="bdt-ep-button-content-wrapper bdt-flex bdt-flex-middle bdt-flex-center"
                                  data-text="Book A Restraunt"
                                >
                                  <div class="bdt-ep-button-text">
                                    <span class="avdbtn-text"
                                      >Book A Restraunt</span
                                    >
                                  </div>
                                </div>
                              </router-link> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="has_eae_slider elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-a2b654a"
                      data-id="a2b654a"
                      data-element_type="column"
                      data-settings='{"background_background":"gradient"}'
                    >
                      <!--START OF Login Area-->
                      <div
                        class="elementor-widget-wrap elementor-element-populated"
                      
                      >
                        <div
                          class="elementor-element elementor-element-406e6fe elementor-widget elementor-widget-bdt-switcher"
                          data-id="406e6fe"
                          data-element_type="widget"
                          data-widget_type="bdt-switcher.default"
                        >
                          <div class="elementor-widget-container">
                            <div
                              id="bdt-tabs-406e6fe"
                              class="bdt-switchers"
                              data-activator='{"id":"406e6fe","switchA":".not-select","switchB":".not-select"}'
                            >
                              <div class="bdt-tabs-container">
                                <div
                                  class="bdt-tab bdt-tab-default"
                                  data-bdt-tab='{"connect":"#bdt-switcher-406e6fe"}'
                                  id="bdt-switcher-activator-406e6fe"
                                >
                                  <!--START OF Login TABS-->
                                  <div class="bdt-tabs-item">
                                    <a
                                      @click="switchLogin('by_card')"
                                      class="bdt-tabs-item-a-title"
                                      href="#"
                                    >
                                      <div class="bdt-tab-text-wrapper">
                                        <span class="bdt-tab-text"
                                          >{{lang.login.loginByCard}}</span
                                        >
                                      </div>
                                    </a>
                                  </div>
                                  <div class="bdt-tabs-item" style="display:none">
                                    <a
                                      @click="switchLogin('by_birth_day')"
                                      class="bdt-tabs-item-b-title"
                                      href="#"
                                    >
                                      <div class="bdt-tab-text-wrapper">
                                        <span class="bdt-tab-text"
                                          >{{lang.login.loginByBirthDay}}</span
                                        >
                                      </div>
                                    </a>
                                  </div>
                                  <!--END OF Login TABS-->
                                </div>
                              </div>
                              <span v-if="errors" style="color: red"
                                >Please check your data</span
                              >
                              <div class="bdt-switcher-wrapper">
                                <div
                                  id="bdt-switcher-406e6fe"
                                  class="bdt-switcher bdt-switcher-item-content"
                                >
                                  <!--START OF Login BY CODE FORM-->
                                  <div class="bdt-switcher-item-content-inner">
                                    <div>
                                      <div
                                        data-elementor-type="section"
                                        data-elementor-id="3533"
                                        class="elementor elementor-3533"
                                        data-elementor-settings="[]"
                                      >
                                        <div class="elementor-section-wrap">
                                          <section
                                            class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-37dd88ae elementor-section-full_width elementor-section-content-middle elementor-section-height-default elementor-section-height-default"
                                            data-particle_enable="false"
                                            data-particle-mobile-disabled="false"
                                            data-id="37dd88ae"
                                            data-element_type="section"
                                          >
                                            <div
                                              class="elementor-container elementor-column-gap-no"
                                            >
                                              <div
                                                class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7ea0b621"
                                                data-id="7ea0b621"
                                                data-element_type="column"
                                              >
                                                <div
                                                  class="elementor-widget-wrap elementor-element-populated"
                                                >
                                                  <div
                                                    class="elementor-element elementor-element-55269da9 elementor-button-align-stretch elementor-widget elementor-widget-form"
                                                    data-id="55269da9"
                                                    data-element_type="widget"
                                                    data-settings='{"button_width":"75","step_next_label":"Next","step_previous_label":"Previous","step_type":"number_text","step_icon_shape":"circle"}'
                                                    data-widget_type="form.default"
                                                  >
                                                    <div
                                                      class="elementor-widget-container"
                                                    >
                                                      <form
                                                        class="elementor-form"
                                                        @submit.prevent="login"
                                                      >
                                                        <input
                                                          type="hidden"
                                                          name="post_id"
                                                          value="3533"
                                                        />
                                                        <input
                                                          type="hidden"
                                                          name="form_id"
                                                          value="55269da9"
                                                        />
                                                        <input
                                                          type="hidden"
                                                          name="referer_title"
                                                          value="Guest Login"
                                                        />
                                                        <input
                                                          type="hidden"
                                                          name="queried_id"
                                                          value="814"
                                                        />
                                                        <div
                                                          class="elementor-form-fields-wrapper elementor-labels-above"
                                                        >
                                                          <div
                                                            class="elementor-field-type-number elementor-field-group elementor-column elementor-field-group-RoomNumber2 elementor-col-100 elementor-field-required elementor-mark-required"
                                                          >
                                                            <label
                                                              for="form-field-RoomNumber2"
                                                              class="elementor-field-label"
                                                              >{{lang.login.roomNumber}}</label
                                                            >
                                                            <input
                                                              v-model="
                                                                room_number
                                                              "
                                                              type="number"
                                                              name="form_fields[RoomNumber2]"
                                                              id="form-field-RoomNumber2"
                                                              class="elementor-field elementor-size-md elementor-field-textual"
                                                              placeholder="Room Number"
                                                              required="required"
                                                              aria-required="true"
                                                              min="0"
                                                              max=""
															          @focus="showKeypad('RoomNumber2', $event)"

                                                            />
															
                                                          </div>
                                                          <div
                                                            class="elementor-field-type-number elementor-field-group elementor-column elementor-field-group-Code elementor-col-100 elementor-field-required elementor-mark-required"
                                                          >
														  
                                                            <label
                                                              for="form-field-Code"
                                                              class="elementor-field-label"
                                                              >{{lang.login.code}}</label
                                                            >
                                                            <input
                                                              v-model="code"
                                                              type="number"
                                                              name="form_fields[Code]"
                                                              id="form-field-Code"
                                                              class="elementor-field elementor-size-md elementor-field-textual"
                                                              placeholder="Code"
                                                              required="required"
                                                              aria-required="true"
                                                              min=""
                                                              max=""
																@focus="showKeypad('code', $event)"
                                                            />
                                                          </div>
                                                          <div
                                                            class="elementor-field-group elementor-column elementor-field-type-submit elementor-col-75 e-form__buttons"
                                                          >
                                                            <button
                                                              type="submit"
                                                              class="elementor-button elementor-size-md"
                                                            >
                                                              <span>
                                                                <span
                                                                  class="elementor-button-icon"
                                                                >
                                                                </span>
                                                                <span
                                                                  class="elementor-button-text"
                                                                  >{{lang.login.login}}</span
                                                                >
                                                              </span>
                                                            </button>
                                                          </div>
                                                        </div>
                                                      </form>
													   <!-- Conditionally render the NumericKeypad based on the environment variable -->
															<NumericKeypad v-if="isKeypadEnabled" ref="keypad" @key-pressed="handleKeyPress" id="vKeyBad" />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </section>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <!--END OF Login BY CODE FORM-->
                                  <!--START OF Login BY BIRTHDAY FORM-->
                                  <div class="bdt-switcher-item-content-inner">
                                    <div>
                                      <div
                                        data-elementor-type="section"
                                        data-elementor-id="3530"
                                        class="elementor elementor-3530"
                                        data-elementor-settings="[]"
                                      >
                                        <div class="elementor-section-wrap">
                                          <section
                                            class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-7edb7976 elementor-section-full_width elementor-section-content-middle elementor-section-height-default elementor-section-height-default"
                                            data-particle_enable="false"
                                            data-particle-mobile-disabled="false"
                                            data-id="7edb7976"
                                            data-element_type="section"
                                          >
                                            <div
                                              class="elementor-container elementor-column-gap-no"
                                            >
                                              <div
                                                class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5536270b"
                                                data-id="5536270b"
                                                data-element_type="column"
                                              >
                                                <div
                                                  class="elementor-widget-wrap elementor-element-populated"
                                                >
                                                  <div
                                                    class="elementor-element elementor-element-58fd065 elementor-button-align-stretch elementor-widget elementor-widget-form"
                                                    data-id="58fd065"
                                                    data-element_type="widget"
                                                    data-settings='{"button_width":"75","step_next_label":"Next","step_previous_label":"Previous","step_type":"number_text","step_icon_shape":"circle"}'
                                                    data-widget_type="form.default"
                                                  >
                                                    <div
                                                      class="elementor-widget-container"
                                                    >
                                                      <form
                                                        class="elementor-form"
                                                        @submit.prevent="login"
                                                      >
                                                        <input
                                                          type="hidden"
                                                          name="post_id"
                                                          value="3530"
                                                        />
                                                        <input
                                                          type="hidden"
                                                          name="form_id"
                                                          value="58fd065"
                                                        />
                                                        <input
                                                          type="hidden"
                                                          name="referer_title"
                                                          value="Guest Login"
                                                        />
                                                        <input
                                                          type="hidden"
                                                          name="queried_id"
                                                          value="814"
                                                        />
                                                        <div
                                                          class="elementor-form-fields-wrapper elementor-labels-above"
                                                        >
                                                          <div
                                                            class="elementor-field-type-number elementor-field-group elementor-column elementor-field-group-RoomNumber elementor-col-100 elementor-field-required elementor-mark-required"
                                                          >
                                                            <label
                                                              for="form-field-RoomNumber"
                                                              class="elementor-field-label"
                                                              >{{lang.login.roomNumber}}</label
                                                            >
                                                            <input
                                                              v-model="
                                                                room_number
                                                              "
                                                              type="number"
                                                              name="form_fields[RoomNumber]"
                                                              id="form-field-RoomNumber"
                                                              class="elementor-field elementor-size-md elementor-field-textual"
                                                              placeholder="Room Number"
                                                              required="required"
                                                              aria-required="true"
                                                              min="0"
                                                              max=""
                                                            />
                                                          </div>
                                                          <div
                                                            class="elementor-field-type-text elementor-field-group elementor-column elementor-field-group-Birthday elementor-col-100 elementor-field-required elementor-mark-required"
                                                          >
                                                            <label
                                                              for="form-field-Birthday"
                                                              class="elementor-field-label"
                                                              >{{lang.login.birthDay}}</label
                                                            >
                                                            <input
                                                              v-model="
                                                                birth_day
                                                              "
                                                              size="1"
                                                              type="date"
                                                              name="form_fields[Birthday]"
                                                              id="form-field-Birthday"
                                                              class="elementor-field elementor-size-md fme-mask-input elementor-field-textual"
                                                              placeholder="dd/mm/yyyy"
                                                              required="required"
                                                              aria-required="true"
                                                              data-fme-mask="ev-date"
                                                            />
                                                          </div>
                                                          <div
                                                            class="elementor-field-group elementor-column elementor-field-type-submit elementor-col-75 e-form__buttons"
                                                          >
                                                            <button
                                                              type="submit"
                                                              class="elementor-button elementor-size-md"
                                                            >
                                                              <span>
                                                                <span
                                                                  class="elementor-button-icon"
                                                                >
                                                                </span>
                                                                <span
                                                                  class="elementor-button-text"
                                                                  >{{lang.login.login}}</span
                                                                >
                                                              </span>
                                                            </button>
                                                          </div>
                                                        </div>
                                                      </form>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </section>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <!--END OF Login BY BIRTHDAY FORM-->
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--END OF Login Area-->
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>
	  
  
    </div>
  </div>
</template>

<script>
import NumericKeypad from './NumericKeypad.vue';

export default {
  components: { NumericKeypad },
  name: "GuestLogin",
  data() {
    return {
      hotelData: {
        hotelLogo: "",
        hotelMainColor:'',
        hotelSecondColor:'',

      },
      login_method: "by_card",
      room_number: null,
      code: null,
      birth_day: null,
      errors: null,
      isLoading: false,
      hotelId :1,
	  lang:"",
      error:false,
	  activeField: null,
	  isKeypadEnabled: process.env.VUE_APP_VKEYBOARD === 'true'

    };
  },
  created() {
      document.addEventListener('click', this.handleClickOutside);

	  this.setLang();
	  console.log("-----------");
	console.log(this.login);
    if (User.loggedIn()) {
      this.$router.push({ name: "BookRestaurant" , id:this.$route.params.id });
    }
    localStorage.setItem('hotel',this.$route.params.id)
    this.getHotelData()
  },
  methods: {
    switchLogin(method) {
      this.login_method = method;
    },
      getHotelData() {
      this.axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "api/hotel-data?api_key=" +
            process.env.VUE_APP_API_KEY +
            "&lang=" +
            this.$i18n.locale +
            "&hotel_id=" +
            this.hotelId
        )
        .then((res) => {
          console.log(res.data.data);
          this.hotelData.hotelLogo =
            process.env.VUE_APP_API_BASE_URL + res.data.data.logo;
          this.hotelData.hotelMainColor = res.data.data.main_color;
          this.hotelData.hotelSecondColor = res.data.data.second_color;
          this.$emit("hotel-data", this.hotelData);
          localStorage.setItem(
            "hotelLogo",
            process.env.VUE_APP_API_BASE_URL + res.data.data.logo
          );
        })
        .catch((error) => {
          console.log(error);
          this.error = true;
        });
    },
    login() {
      var formData = {
        api_key: process.env.VUE_APP_API_KEY,
        lang: this.$i18n.locale ,
        login_method: this.login_method,
        hotel_id: 1,
        room_number: this.room_number,
      };
      if (this.login_method === "by_card") {
        formData["code"] = this.code;
      } else if (this.login_method === "by_birth_day") {
        formData["birth_day"] = this.birth_day;
      }
      this.isLoading = true;
          console.log(formData);

      this.axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "api/login?api_key=" +
            process.env.VUE_APP_API_KEY +
            "&lang=" +
            localStorage.getItem("localization")+
			"&login_method="+this.login_method+
			"&hotel_id=1"+
			"&room_number="+this.room_number+
			"&code="+this.code
        )
        .then((res) => {
          this.isLoading = false;

          if(res.data.status	== "ERROR"){
            this.$notify({
              group: "foo",
              title: "Invalid Login",
              text: res.data.message,
              type: "error",
            });
            return;
          }
          User.responseAfterLogin(res);
    
          // this.$notify({
          //   group: "foo",
          //   title: "Success",
          //   text: "Welcome you have successfuly logged in ",
          //   type: "success",
          // });
          this.$router.push({ name: "Profile" });
        })
        .catch((error) => {
          this.isLoading = false;

          this.errors = error;
          this.$notify({
            group: "foo",
            title: "Invalid Login",
            text: "Something error in login please check your inputs and try again ",
            type: "error",
          });
          console.log(error);
        });
    },
    test() {
      this.$notify({
        group: "foo",
        title: "Important message 32r2r",
        text: "Hello user! This is a notification!",
        type: "error",
      });
    },
	setLang() {
      this.axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "api/frontend-keywords?api_key=" +
            process.env.VUE_APP_API_KEY +
            "&lang=" +
            localStorage.getItem("localization")
        )
        .then(
          (res) => {
			this.lang = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
	showKeypad(field, event) {
		if(!this.isKeypadEnabled)
			return;
      this.activeField = field;
	  //alert(this.$refs.keypad);
	   this.$refs.keypad.show();
    },
    hideKeypad() {
            //this.isKeypadEnabled = false;
			this.$refs.keypad.hide();
    },
    handleKeyPress(key) {
	if (this.activeField === 'code') {
		let currentValue1=document.getElementById("form-field-Code").value;
		currentValue1=currentValue1+key;
		this.code=currentValue1;
  } else if (this.activeField === 'RoomNumber2') {
		let currentValue=document.getElementById("form-field-RoomNumber2").value;
		currentValue=currentValue+key;
		this.room_number=currentValue;
		}
    },
    handleClickOutside(event) {
     const keypad = this.$refs.keypad?.$el;
	  if (keypad && !keypad.contains(event.target) && event.target.tagName !== 'INPUT') {
		this.hideKeypad();
	  }
    }
  },
  computed:{
     
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
<!-- eslint-enable -->
