<!-- eslint-disable -->
<template>
  <div id="container" class="hfeed wpbf-page">
    <loader
      object="#ff9633"
      v-if="isLoading"
      color1="#ffffff"
      color2="#999793"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="80"
      name="spinning"
    ></loader>
    <div
      data-elementor-type="wp-page"
      data-elementor-id="1473"
      class="elementor elementor-1473"
      data-elementor-settings="[]"
    >
      <div class="elementor-section-wrap">
        <section
          class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-section-full_width elementor-section-height-full elementor-section-content-middle elementor-section-stretched elementor-section-height-default elementor-section-items-middle"
          data-particle_enable="false"
          data-particle-mobile-disabled="false"
          :style="
            this.$i18n.locale === 'ar'
              ? 'direction:rtl ; text-align:right !important'
              : ''
          "
          data-id="b3b6b9b"
          data-element_type="section"
          data-settings='{"stretch_section":"section-stretched"}'
        >
          <div class="elementor-background-overlay"></div>
          <div
            class="elementor-container elementor-column-gap-no"
            style="margin-top: -60px"
          >
            <div
              class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-69f4a96"
              data-id="69f4a96"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <section
                  class="has_eae_slider elementor-section elementor-inner-section elementor-element elementor-element-5015be9 elementor-section-full_width elementor-section-content-middle elementor-section-height-default elementor-section-height-default"
                  data-particle_enable="false"
                  data-particle-mobile-disabled="false"
                  data-id="5015be9"
                  data-element_type="section"
                >
                  <div class="elementor-container elementor-column-gap-no">
                    <!--START of RESTRAURANT PROFILE-->
                    <div
                      class="has_eae_slider elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-cd632d7"
                      data-id="cd632d7"
                      data-element_type="column"
                    >
                      <div
                        class="elementor-widget-wrap elementor-element-populated"
                      >
                        <div
                          class="elementor-element elementor-element-6441c1f elementor-widget__width-auto elementor-widget-mobile__width-auto elementor-widget elementor-widget-image"
                          data-id="6441c1f"
                          data-element_type="widget"
                          data-widget_type="image.default"
                        >
                          <div class="elementor-widget-container">
                            <img
                              width="150"
                              height="150"
                              :src="resturantData.resturantProfileImage"
                              class="attachment-thumbnail size-thumbnail"
                              alt=""
                              loading="lazy"
                              style="height: 6vh"
                              sizes="(max-width: 150px) 100vw, 150px"
                            />
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-7f18d19 text-left elementor-widget__width-auto elementor-widget elementor-widget-tp-heading-title"
                          data-id="7f18d19"
                          data-element_type="widget"
                          data-widget_type="tp-heading-title.default"
                        >
                          <div class="elementor-widget-container">
                            <div
                              class="heading heading_style heading_style61ca7f7ca0fa7 style-1"
                            >
                              <div class="sub-style">
                                <div class="head-title">
                                  <h2
                                    class="heading-title"
                                    data-hover="Italian Restaurant"
                                  >
                                    {{ resturantData.resturantName
                                    }}<span class="title-s"> </span>
                                  </h2>
                                </div>
                                <div class="sub-heading">
                                  <h3 class="heading-sub-title">
                                    {{ resturantData.resturantCategory }}
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--START of RESTRAURANT PROFILE-->
                    <div
                      class="has_eae_slider elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-5feb9c7"
                      data-id="5feb9c7"
                      data-element_type="column"
                    >
                      <div
                        class="elementor-widget-wrap elementor-element-populated"
                      >
                        <div
                          class="elementor-element elementor-element-9615876 elementor-widget__width-auto elementor-widget-elementskit-dual-button-center elementor-widget-elementskit-dual-button-mobile-center elementor-widget-elementskit-dual-button-tablet-center elementor-widget elementor-widget-elementskit-dual-button"
                          data-id="9615876"
                          data-element_type="widget"
                          data-widget_type="elementskit-dual-button.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="ekit-wid-con">
                              <div class="ekit-element-align-wrapper">
                                <div class="ekit_double_button">
                                  <router-link
                                    class="ekit-double-btn ekit-double-btn-one"
                                    to="login"
                                    >{{ lang.menu.bookNow}}<i
                                      aria-hidden="true"
                                      class="far fa-calendar-check"
                                    ></i>
                                  </router-link>
                                  <span class="ekit_button_middle_text">{{
                                    lang.menu.or
                                  }}</span>
                                  <router-link
                                    class="ekit-double-btn ekit-double-btn-two"
                                    to="book"
                                    >{{
                                      lang.menu.chooseAnotherRestraunt
                                    }}</router-link
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  class="has_eae_slider elementor-section elementor-inner-section elementor-element elementor-element-4391528 elementor-section-full_width elementor-section-content-middle elementor-section-height-default elementor-section-height-default"
                  data-particle_enable="false"
                  data-particle-mobile-disabled="false"
                  data-id="4391528"
                  data-element_type="section"
                >
                  <div class="elementor-container elementor-column-gap-no">
                    <!--START of MENU SECTION-->
                    <div
                      class="has_eae_slider elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-20c44bb"
                      data-id="20c44bb"
                      data-element_type="column"
                    >
                      <div
                        class="elementor-widget-wrap elementor-element-populated"
                      >
                        <div
                          class="elementor-element elementor-element-21a8408 elementor-widget elementor-widget-eael-adv-tabs"
                          data-id="21a8408"
                          data-element_type="widget"
                          data-widget_type="eael-adv-tabs.default"
                        >
                          <div class="elementor-widget-container">
                            <div
                              id="eael-advance-tabs-21a8408"
                              class="eael-advance-tabs eael-tabs-horizontal active-caret-on"
                              data-tabid="21a8408"
                            >
                              <div class="eael-tabs-nav">
                                <!--START of MENU TABS-->
                                <!-- <testswiper /> -->

                                <swiper
                                  :slides-per-view="slideSize"
                                  :space-between="0"
                                  :loop="false"
                                  class="eael-tabs-nav"
                                >
                                  <swiper-slide
                                    id="appetizers"
                                    v-for="menucategory in menus"
                                    :key="menucategory.id"
                                    style="width: 200px; height: 40px"
                                  >
                                    <div
                                      v-bind:class="{
                                        swiperStyle:
                                          activeTabId == menucategory.id,
                                      }"
                                      class="active-default eael-tab-inline-icon swiperStyle2"
                                      @click="activeTab(menucategory)"
                                    >
                                      <!--img
                                        :src="menucategory.categoryIcon"
                                        alt=""
                                        style="width: 20px; height: 20px"
                                      />--> 
                                      <span
                                        class="eael-tab-title title-after-icon spanStyle"
                                        style="fontsize: 15px"
                                        >{{ menucategory.categoryName }}</span
                                      >
                                    </div>
                                  </swiper-slide>
                                </swiper>
                                <!-- <ul class="eael-tab-inline-icon">
                                  <li
                                    id="appetizers"
                                    v-for="menucategory in menuCategories"
                                    :key="menucategory.categoryId"
                                    class="active-default"
                                    v-bind:class="{
                                      active: menucategory.isActiveTab,
                                    }"
                                    @click="activeTab(menucategory)"
                                  >
                                    <img
                                      :src="menucategory.categoryIcon"
                                      alt=""
                                    />
                                    <span
                                      class="eael-tab-title title-after-icon"
                                      >{{ menucategory.categoryName }}</span
                                    >
                                  </li>
                                </ul> -->
                                <!--END of MENU TABS-->
                              </div>
                              <div v-for="category in menus" :key="category.id">
                                <div
                                  class="eael-tabs-content"
                                  v-show="activeTabId == category.id"
                                >
                                  <!--START OF APPETIZER TAB)-->
                                  <div
                                    id="appetizers-tab"
                                    class="clearfix eael-tab-content-item active-default active"
                                    data-title-link="appetizers"
                                  >
                                    <div
                                      data-elementor-type="page"
                                      data-elementor-id="1520"
                                      class="elementor elementor-1520"
                                      data-elementor-settings="[]"
                                    >
                                      <div
                                        class="elementor-section-wrap .elementor-1520 .elementor-element.elementor-element-64d82d2"
                                      >
                                        <section
                                          class="theplus-equal-height has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-64d82d2 elementor-section-content-middle elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
                                          data-tp-equal-height-loadded=".elementor-heading-title elementor-size-default"
                                          data-particle_enable="false"
                                          data-particle-mobile-disabled="false"
                                          data-id="64d82d2"
                                          data-element_type="section"
                                          data-settings='{"background_background":"classic"}'
                                        >
                                          <div
                                            class="elementor-container elementor-column-gap-no"
                                          >
                                            <div
                                              class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5f364aa"
                                              data-id="5f364aa"
                                              data-element_type="column"
                                            >
                                              <!--START of MENU ITEMS PAGE (APPETIZER TAB CONTENT)-->
                                              <div
                                                class="elementor-widget-wrap elementor-element-populated"
                                              >
                                                <!--START of SINGLE ITEM (DOUBLICATE THIS DIV TO ADD MORE ITEMS)-->
                                                <div
                                                  class="theplus-equal-height elementor-element elementor-element-41c4a22 elementor-widget__width-auto elementor-widget elementor-widget-template"
                                                  data-tp-equal-height-loadded=".cardMaxWidth"
                                                  data-id="41c4a22"
                                                  data-element_type="widget"
                                                  data-widget_type="template.default"
                                                  v-for="categoryItem in category.menuItems"
                                                  :key="categoryItem.id"
                                                >
                                                  <div
                                                    class="elementor-widget-container"
                                                    v-if="categoryItem.name"
                                                  >
                                                    <div
                                                      class="elementor-template"
                                                    >
                                                      <div
                                                        data-elementor-type="page"
                                                        data-elementor-id="3314"
                                                        class="elementor elementor-3314"
                                                        data-elementor-settings="[]"
                                                      >
                                                        <div
                                                          class="elementor-section-wrap"
                                                        >
                                                          <section
                                                            class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-7a8c802 elementor-section-content-middle elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
                                                            data-particle_enable="false"
                                                            data-particle-mobile-disabled="false"
                                                            data-id="7a8c802"
                                                            data-element_type="section"
                                                          >
                                                            <div
                                                              class="elementor-container elementor-column-gap-no"
                                                            >
                                                              <div
                                                                class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-74ac8da"
                                                                data-id="74ac8da"
                                                                data-element_type="column"
                                                              >
                                                                <div
                                                                  class="elementor-widget-wrap elementor-element-populated"
                                                                >
                                                                  <div
                                                                    class="elementor-element elementor-element-fca473f elementor-widget__width-initial elementor-widget elementor-widget-image"
                                                                    data-id="fca473f"
                                                                    data-element_type="widget"
                                                                    data-widget_type="image.default"
                                                                  >
                                                                    <div
                                                                      class="elementor-widget-container"
                                                                    >
                                                                      <img
                                                                        width="350"
                                                                        height="350"
                                                                        :src="
                                                                          baseUrl +
                                                                          categoryItem.image
                                                                        "
                                                                        class="attachment-full size-full"
                                                                        alt=""
                                                                        style="
                                                                          object-fit: cover;
                                                                          padding: 15px;
                                                                        "
                                                                        loading="lazy"
                                                                        sizes="(max-width: 350px) 100vw, 350px"
                                                                      />
                                                                    </div>
                                                                  </div>
                                                                  <div
                                                                    class="elementor-element elementor-element-d34f412 elementor-widget__width-initial elementor-widget-mobile__width-initial elementor-widget elementor-widget-heading"
                                                                    data-id="d34f412"
                                                                    data-element_type="widget"
                                                                    data-widget_type="heading.default"
                                                                  >
                                                                    <div
                                                                      class="elementor-widget-container"
                                                                    >
                                                                      <h2
                                                                        class="elementor-heading-title elementor-size-default"
                                                                      >
                                                                        {{
                                                                          categoryItem.name
                                                                        }}
                                                                      </h2>
                                                                    </div>
                                                                  </div>
                                                                  <div
                                                                    class="elementor-element elementor-element-c5d6b6c DesHeight elementor-widget elementor-widget-text-editor"
                                                                    data-id="c5d6b6c"
                                                                    data-element_type="widget"
                                                                    data-widget_type="text-editor.default"
                                                                  >
                                                                    <div
                                                                      class="elementor-widget-container"
                                                                    >
                                                                      {{
                                                                        categoryItem.short_description
                                                                      }}
                                                                    </div>
                                                                  </div>
                                                                  <section
                                                                    class="has_eae_slider elementor-section elementor-inner-section elementor-element elementor-element-85cf75e elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                                    data-particle_enable="false"
                                                                    data-particle-mobile-disabled="false"
                                                                    data-id="85cf75e"
                                                                    data-element_type="section"
                                                                  >
                                                                    <div
                                                                      class="elementor-container elementor-column-gap-no"
                                                                    >
                                                                      <div
                                                                        class="has_eae_slider elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-0264393"
                                                                        data-id="0264393"
                                                                        data-element_type="column"
                                                                      >
                                                                        <div
                                                                          class="elementor-widget-wrap elementor-element-populated"
                                                                        >
                                                                          <div
                                                                            class="elementor-element elementor-element-1509f2d premium-header-inline elementor-widget elementor-widget-premium-addon-dual-header"
                                                                            data-id="1509f2d"
                                                                            data-element_type="widget"
                                                                            data-widget_type="premium-addon-dual-header.default"
                                                                          >
                                                                            <div
                                                                              class="elementor-widget-container"
                                                                            >
                                                                              <div
                                                                                class="premium-dual-header-container"
                                                                              >
                                                                                <div
                                                                                  class="premium-dual-header-first-container"
                                                                                >
                                                                                  <h2
                                                                                    class="premium-dual-header-first-header"
                                                                                    v-if="
                                                                                      categoryItem.new_price
                                                                                    "
                                                                                  >
                                                                                    <span
                                                                                      class="premium-dual-header-first-span"
                                                                                      >{{
                                                                                        categoryItem.new_price.toFixed(
                                                                                          2
                                                                                        )
                                                                                      }} </span
                                                                                    ><span
                                                                                      class="premium-dual-header-second-header"
                                                                                    >
                                                                                      {{currency}}
                                                                                    </span>
                                                                                  </h2>
                                                                                  <h2
                                                                                    class="premium-dual-header-first-header"
                                                                                    v-if="
                                                                                      !categoryItem.new_price
                                                                                    "
                                                                                  >
                                                                                    <span
                                                                                      class="premium-dual-header-first-span"
                                                                                      >{{
                                                                                        categoryItem.price
                                                                                      }} </span
                                                                                    ><span
                                                                                      class="premium-dual-header-second-header"
                                                                                    >
                                                                                      {{currency}}
                                                                                    </span>
                                                                                  </h2>
                                                                                  <br />
                                                                                  <h4
                                                                                    class="premium-dual-header-first-header"
                                                                                    v-if="
                                                                                      categoryItem.new_price
                                                                                    "
                                                                                  >
                                                                                    <span
                                                                                      style="
                                                                                        color: grey;
                                                                                        -webkit-text-decoration-line: line-through;
                                                                                        text-decoration-line: line-through;
                                                                                      "
                                                                                      class="premium-dual-header-first-span"
                                                                                      >{{
                                                                                        categoryItem.price
                                                                                      }} </span
                                                                                    ><span
                                                                                      class="premium-dual-header-second-header"
                                                                                    >
                                                                                      {{currency}}
                                                                                    </span>
                                                                                  </h4>
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                      <div
                                                                        class="has_eae_slider elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-891a22c"
                                                                        data-id="891a22c"
                                                                        data-element_type="column"
                                                                      >
                                                                        <div
                                                                          class="elementor-widget-wrap elementor-element-populated"
                                                                        >
                                                                          <div
                                                                            class="elementor-element elementor-element-46919a4 elementor-align-justify elementor-widget elementor-widget-bdt-advanced-button"
                                                                            data-id="46919a4"
                                                                            data-element_type="widget"
                                                                            data-widget_type="bdt-advanced-button.default"
                                                                          >
                                                                            <div
                                                                              class="elementor-widget-container"
                                                                            >
                                                                              <div
                                                                                class="bdt-ep-button-wrapper"
                                                                              >
                                                                                <a
                                                                                  v-if="
                                                                                    isItemExistInCart(
                                                                                      categoryItem.id
                                                                                    )
                                                                                  "
                                                                                  @click="
                                                                                    removeCart(
                                                                                      categoryItem.id
                                                                                    )
                                                                                  "
                                                                                  class="bdt-ep-button bdt-ep-button-effect-a bdt-ep-button-size-sm"
                                                                                >
                                                                                  <div
                                                                                    class="bdt-ep-button-content-wrapper bdt-flex bdt-flex-middle bdt-flex-center"
                                                                                    data-text="-"
                                                                                  >
                                                                                    <div
                                                                                      class="bdt-ep-button-icon bdt-flex-center bdt-flex-align-right"
                                                                                    >
                                                                                      <div
                                                                                        class="bdt-ep-button-icon-inner"
                                                                                      >
                                                                                        <i
                                                                                          aria-hidden="true"
                                                                                          class="fa-fw fas fa-cart-arrow-down"
                                                                                        ></i>
                                                                                      </div>
                                                                                    </div>
                                                                                    <div
                                                                                      class="bdt-ep-button-text"
                                                                                    >
                                                                                      <span
                                                                                        class="avdbtn-text"
                                                                                        >-</span
                                                                                      >
                                                                                    </div>
                                                                                  </div>
                                                                                </a>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                      <div
                                                                        class="has_eae_slider elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-b9a5d4d"
                                                                        data-id="b9a5d4d"
                                                                        data-element_type="column"
                                                                      >
                                                                        <div
                                                                          class="elementor-widget-wrap elementor-element-populated"
                                                                        >
                                                                          <div
                                                                            class="elementor-element elementor-element-b358ccb elementor-align-justify elementor-widget elementor-widget-bdt-advanced-button"
                                                                            data-id="b358ccb"
                                                                            data-element_type="widget"
                                                                            data-widget_type="bdt-advanced-button.default"
                                                                          >
                                                                            <div
                                                                              class="elementor-widget-container"
                                                                            >
                                                                              <div
                                                                                class="bdt-ep-button-wrapper"
                                                                              >
                                                                                <a
                                                                                  @click="
                                                                                    addToCart(
                                                                                      categoryItem
                                                                                    )
                                                                                  "
                                                                                  href="#"
                                                                                  class="bdt-ep-button bdt-ep-button-effect-a bdt-ep-button-size-sm"
                                                                                >
                                                                                  <div
                                                                                    class="bdt-ep-button-content-wrapper bdt-flex bdt-flex-middle bdt-flex-center"
                                                                                    data-text="+"
                                                                                  >
                                                                                    <div
                                                                                      class="bdt-ep-button-icon bdt-flex-center bdt-flex-align-left"
                                                                                    >
                                                                                      <div
                                                                                        class="bdt-ep-button-icon-inner"
                                                                                      >
                                                                                        <i
                                                                                          aria-hidden="true"
                                                                                          class="fa-fw fas fa-cart-plus"
                                                                                        ></i>
                                                                                      </div>
                                                                                    </div>
                                                                                    <div
                                                                                      class="bdt-ep-button-text"
                                                                                    >
                                                                                      <span
                                                                                        class="avdbtn-text"
                                                                                        >+</span
                                                                                      >
                                                                                    </div>
                                                                                  </div>
                                                                                </a>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </section>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </section>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <!--END of SINGLE ITEM (DOUBLICATE THIS DIV TO ADD MORE ITEMS)-->
                                              </div>
                                              <!--END of MENU ITEMS PAGE (APPETIZER TAB CONTENT)-->
                                            </div>
                                          </div>
                                        </section>
                                      </div>
                                    </div>
                                  </div>
                                  <!--END OF APPETIZER TAB)-->
                                </div>
                              </div>
                              <div v-if="menus.length == 0">
                                <div class="eael-tabs-content">
                                  <!--START OF APPETIZER TAB)-->
                                  <div
                                    id="appetizers-tab"
                                    class="clearfix eael-tab-content-item active-default active"
                                    data-title-link="appetizers"
                                  >
                                    <div
                                      data-elementor-type="page"
                                      data-elementor-id="1520"
                                      class="elementor elementor-1520"
                                      data-elementor-settings="[]"
                                    >
                                      <div
                                        class="elementor-section-wrap .elementor-1520 .elementor-element.elementor-element-64d82d2"
                                      >
                                        <section
                                          class="theplus-equal-height has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-64d82d2 elementor-section-content-middle elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
                                          data-tp-equal-height-loadded=".elementor-heading-title elementor-size-default"
                                          data-particle_enable="false"
                                          data-particle-mobile-disabled="false"
                                          data-id="64d82d2"
                                          data-element_type="section"
                                          data-settings='{"background_background":"classic"}'
                                        >
                                          <div
                                            class="elementor-container elementor-column-gap-no"
                                          >
                                            <div
                                              class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5f364aa"
                                              data-id="5f364aa"
                                              data-element_type="column"
                                            >
                                              <!--START of MENU ITEMS PAGE (APPETIZER TAB CONTENT)-->
                                              <div
                                                class="elementor-widget-wrap elementor-element-populated"
                                              >
                                                <!--START of SINGLE ITEM (DOUBLICATE THIS DIV TO ADD MORE ITEMS)-->
                                                <h1>
                                                  Sorry no menu for this
                                                  resturant
                                                </h1>
                                                <br />

                                                <!--END of SINGLE ITEM (DOUBLICATE THIS DIV TO ADD MORE ITEMS)-->
                                              </div>
                                              <br />
                                              <router-link class="" to="book"
                                                >Choose Another
                                                Restaurant</router-link
                                              >
                                              <!--END of MENU ITEMS PAGE (APPETIZER TAB CONTENT)-->
                                            </div>
                                          </div>
                                        </section>
                                      </div>
                                    </div>
                                  </div>
                                  <!--END OF APPETIZER TAB)-->
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--END of MENU SECTION-->

                    <!--START OF ORDER SUMMARY AREA)-->
                    <div
                      class="has_eae_slider elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-0568dc0"
                      data-id="0568dc0"
                      data-element_type="column"
                      data-settings='{"background_background":"classic"}'
                    >
                      <div
                        class="elementor-widget-wrap elementor-element-populated ::-webkit-scrollbar-thumb"
                        style="max-height: 69vh; overflow-y: scroll"
                      >
                        <div
                          class="elementor-element elementor-element-be3d107 elementor-widget elementor-widget-heading"
                          data-id="be3d107"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h2
                              class="elementor-heading-title elementor-size-default"
                            >
                              {{ lang.menu.orderSummary }}
                            </h2>

                            <a
                              href="#"
                              @click="clearCart()"
                              class="bdt-ep-button bdt-ep-button-effect-a bdt-clear-buttton"
                            >
                              <span class="plus-table__text">
                                <span class="plus-align-icon--left">
                                  {{ lang.menu.clearCart }}
                                  <i
                                    aria-hidden="true"
                                    class="fa-fw fas fa-eraser"
                                  ></i>
                                </span>
                              </span>
                            </a>
                          </div>
                        </div>
                        <!--START OF ORDER CART-->
                        <div
                          class="elementor-element elementor-element-a10ca45 elementor-widget__width-auto elementor-widget elementor-widget-tp-table"
                          data-id="a10ca45"
                          data-element_type="widget"
                          data-widget_type="tp-table.default"
                          style="width: 90%"
                        >
                          <div class="elementor-widget-container">
                            <div itemscope class="plus-table-wrapper">
                              <table
                                id="plus-table-id-a10ca45"
                                class="plus-table plus-text-break plus-column-rules"
                                data-sort-table="no"
                                data-show-entry="no"
                                data-searchable="no"
                              >
                                <thead>
                                  <tr class="plus-table-row">
                                    <th
                                      id="tooltip61ca7f7dd95e2"
                                      data-sort="0"
                                      class="sort-this elementor-repeater-item-3546501 plus-table-col"
                                      colspan="2"
                                      data-local="true"
                                      scope="col"
                                    >
                                      <span class="sort-style">
                                        <span
                                          class="plus-table__text"
                                          :style="
                                            this.$i18n.locale === 'ar'
                                              ? 'direction:rtl ; margin : 0'
                                              : ''
                                          "
                                        >
                                          <span
                                            class="plus-table__text-inner"
                                            >{{ lang.menu.items }}</span
                                          >
                                        </span>
                                      </span>
                                    </th>
                                    <th
                                      id="tooltip61ca7f7dd9617"
                                      data-sort="1"
                                      class="sort-this elementor-repeater-item-443f607 plus-table-col"
                                      data-local="true"
                                      scope="col"
                                    >
                                      <span class="sort-style">
                                        <span class="plus-table__text">
                                          <span
                                            class="plus-table__text-inner"
                                            >{{ lang.menu.qty }}</span
                                          >
                                        </span>
                                      </span>
                                    </th>
                                    <th
                                      id="tooltip61ca7f7dd9629"
                                      data-sort="2"
                                      class="sort-this elementor-repeater-item-7949b0f plus-table-col"
                                      data-local="true"
                                      scope="col"
                                    >
                                      <span class="sort-style">
                                        <span class="plus-table__text">
                                          <span
                                            class="plus-table__text-inner"
                                            >{{ lang.menu.price }}</span
                                          >
                                        </span>
                                      </span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <!-- ROWS -->
                                  <tr
                                    data-entry="1"
                                    class="plus-table-row"
                                    v-if="!cart.items.length"
                                  >
                                    <td
                                      id="tooltip61ca7f7dd964b"
                                      class="plus-table-col elementor-repeater-item-abc091f"
                                      data-title="Items"
                                      colspan="4"
                                    >
                                      {{  $t("menu.noItem") }}
                                    </td>
                                  </tr>
                                  <tr
                                    data-entry="1"
                                    :class="{
                                      'no-discount': item.discount == 0,
                                      'plus-table-row': true,
                                    }"
                                    v-for="item in cart.items"
                                    :key="item.item_id"
                                  >
                                    <td
                                      id="tooltip61ca7f7dd964b"
                                      class="plus-table-col elementor-repeater-item-abc091f"
                                      data-title="Items"
                                    >
                                      <a
                                        href="#"
                                        @click="removeCart(item.item_id)"
                                        class="tb-col-link"
                                      >
                                        <span class="plus-table__text">
                                          <span class="plus-align-icon--left">
                                            <i class="iconsmind-Remove"></i>
                                          </span>
                                        </span>
                                      </a>
                                    </td>
                                    <td
                                      id="tooltip61ca7f7dd9664"
                                      class="plus-table-col elementor-repeater-item-86defdb"
                                      data-title="Qty"
                                    >
                                      <span class="plus-table__text">
                                        <span class="plus-table__text-inner">{{
                                          item.name
                                        }}</span>
                                      </span>
                                    </td>
                                    <td
                                      id="tooltip61ca7f7dd9673"
                                      class="plus-table-col elementor-repeater-item-f34fecf"
                                      data-title="Price"
                                    >
                                      <span class="plus-table__text">
                                        <span class="plus-table__text-inner">{{
                                          item.quantity
                                        }}</span>
                                      </span>
                                    </td>
                                    <td
                                      id="tooltip61ca7f7dd9680"
                                      class="plus-table-col elementor-repeater-item-234042a"
                                    >
                                      <span class="plus-table__text">
                                        <span
                                          v-if="item.purchased_price"
                                          class="plus-table__text-inner"
                                          >{{ item.purchased_price }}
                                          {{currency}} </span
                                        >
                                      </span>
                                      <span
                                        v-if="!item.purchased_price"
                                        class="plus-table__text-inner"
                                        >{{ item.original_price }}
                                         {{currency}}</span
                                      >
                                    </td>
                                  </tr>

                                  <tr data-entry="3" class="plus-table-row">
                                    <td
                                      id="tooltip61ca7f7dd96d2"
                                      class="plus-table-col elementor-repeater-item-3d66e59"
                                      colspan="3"
                                      data-title="Items"
                                    >
                                      <span class="plus-table__text">
                                        <span class="plus-table__text-inner"
                                          >{{ lang.menu.subTotal }}:</span
                                        >
                                      </span>
                                    </td>
                                    <td
                                      id="tooltip61ca7f7dd96df"
                                      class="plus-table-col elementor-repeater-item-c4cdd53"
                                      data-title="Qty"
                                    >
                                      <span class="plus-table__text">
                                        <span class="plus-table__text-inner"
                                          >{{ cart.subtotal }}
                                          {{currency}}</span
                                        >
                                      </span>
                                    </td>
                                  </tr>
                                  <tr data-entry="4" class="plus-table-row">
                                    <td
                                      id="tooltip61ca7f7dd96f3"
                                      class="plus-table-col elementor-repeater-item-5471dca"
                                      colspan="3"
                                      data-title="Items"
                                    >
                                      <span class="plus-table__text">
                                        <span class="plus-table__text-inner"
                                          >{{ lang.menu.tax }}:</span
                                        >
                                      </span>
                                    </td>
                                    <td
                                      id="tooltip61ca7f7dd9701"
                                      class="plus-table-col elementor-repeater-item-687121f"
                                      data-title="Qty"
                                    >
                                      <span class="plus-table__text">
                                        <span class="plus-table__text-inner"
                                          >{{ cart.tax }}%</span
                                        >
                                      </span>
                                    </td>
                                  </tr>
                                  <tr data-entry="5" class="plus-table-row">
                                    <td
                                      id="tooltip61ca7f7dd9714"
                                      class="plus-table-col elementor-repeater-item-42833b2"
                                      colspan="3"
                                      data-title="Items"
                                    >
                                      <span class="plus-table__text">
                                        <span class="plus-table__text-inner"
                                          >{{ lang.menu.grandTotal }}:</span
                                        >
                                      </span>
                                    </td>
                                    <td
                                      id="tooltip61ca7f7dd9721"
                                      class="plus-table-col elementor-repeater-item-c37fc87"
                                      data-title="Qty"
                                    >
                                      <span class="plus-table__text">
                                        <span class="plus-table__text-inner"
                                          >{{ cart.total_price }}
                                          {{currency}}</span
                                        >
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <!--END OF ORDER CART-->
                        <div
                          class="elementor-element elementor-element-1a48d02 elementor-align-justify elementor-widget elementor-widget-bdt-advanced-button"
                          data-id="1a48d02"
                          data-element_type="widget"
                          data-widget_type="bdt-advanced-button.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="bdt-ep-button-wrapper">
                              <router-link v-if="cart.id != undefined && cart.items.length"
                                :to="
                                  'checkout/rest_' +
                                  restaurantID +
                                  '/order_' +
                                  cart.id
                                "
                                class="bdt-ep-button bdt-ep-button-effect-a bdt-ep-button-size-md elementor-animation-push"
                              >
                                <div
                                  class="bdt-ep-button-content-wrapper bdt-flex bdt-flex-middle bdt-flex-center"
                                  data-text="Checkout"
                                >
                                  <div class="bdt-ep-button-text">
                                    <span class="avdbtn-text">{{
                                      lang.menu.checkOut
                                    }}</span>
                                  </div>
                                </div>
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--END OF ORDER SUMMARY AREA)-->
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div id="confirmation-modal" class="modal">
      <div class="modal-content">
        <div class="container">
          <h2 class="confirm-title">{{  lang.confirmation.ConfirmationMessage }}</h2>
          <p>{{  lang.confirmation.itemExtra }}</p>
          <p><b>{{lang.confirmation.sueAdd }}</b></p>

          <div class="clearfix">
            <button type="button" class="cancelbtn" @click="cancelConfirm()">
              {{lang.confirmation.cancel }}
            </button>
            <button style="display:none"
              type="button"
              class="confirmbtn"
              @click="confirmAdd()"
              data-id=""
            >
              {{ lang.confirmation.confirm }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Navigation, Pagination } from "swiper";

import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";

// Import Swiper styles
import "swiper/swiper-bundle.css";

SwiperCore.use([Navigation, Pagination]);

// import testswiper from './testswiper.vue';
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  name: "Menu",
  data() {
    return {
      hotelId: 1,
      ids: this.$route.params.menuId,
      restaurantID:"" ,
      bookingID:"" ,
      hotelResturantsData: [],
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      activeTabId: 0,
      slideSize: "3.5",
      resturantData: {
        resturantCoverImage: "",
        resturantProfileImage: "",
        resturantName: "",
        resturantCategory: "",
      },
      isLoading: true,
      menus: [],
      cart: {
        items: [],
        tax: 0,
        total_price: 0,
        subtotal: 0,
      },
      categoeyItemsInCart: [{}],
	  guestData:[],
      itemsInCart: [],
      orderId: "",
      lang: "",
	  currency:""
    };
  },
  created() {
    this.getHotelData();
    this.getMenuData();
    this.getCartData();
    this.setLang();
	if(localStorage.getItem("localization")==null){
		this.$i18n.locale = 'en';
      localStorage.setItem('localization', this.$i18n.locale);
	  }
	this.getGuestInfo();
	this.currency=localStorage.getItem("currency");
  },

  methods: {
    isItemExistInCart(id) {
      if (this.itemsInCart.includes(id)) {
        return true;
      } else {
        return false;
      }
    },
    isCategoryExistInCart(categoryId) {
      if (this.categoeyItemsInCart.includes(categoryId)) {
        return true;
      } else {
        return false;
      }
    },
	/*getCurrentCategoryItemsCount(cartCategories,categoryId){
		for(catInCartCount=0;catInCartCount<cartCategories.length;catInCartCount++){
			if(cartCategories[catInCartCount].categoryId==categoryId){
				return cartCategories[catInCartCount].noOfItems;
			}
		}
		return 0;
	},*/
	
	getCurrentCategoryItemsCount(cartCategories, categoryId) {
		  const category = cartCategories.find(cat => cat.categoryId === categoryId);
		  return category ? category.noOfItems : 0;
	},
	updateCurrentCategoryItemsCount(cartCategories,categoryId,itemCount){
		for(let catInCartCount=0;catInCartCount<cartCategories.length;catInCartCount++){
			if(cartCategories[catInCartCount].categoryId==categoryId){
				 cartCategories[catInCartCount].noOfItems=itemCount;
				 break;
			}
		}
		cartCategories.push({"categoryId":categoryId,"noOfItems":itemCount});
	},
	isMaxItemsforCategoryReached(cartCategories,categoryId,numberOfCompanions) {
		//get number of itemsFromSameCategory
		for(let catInCartCount=0;catInCartCount<cartCategories.length;catInCartCount++){
			if(cartCategories[catInCartCount].categoryId==categoryId){
				console.log(" No of Items of this category is "+cartCategories[catInCartCount].noOfItems);
				if(cartCategories[catInCartCount].noOfItems>=numberOfCompanions)
					return true
				else 
					return false
			}
		}
	},
	getGuestInfo(){
		this.axios
      .get(
        process.env.VUE_APP_API_BASE_URL +
          "api/user-profile?api_key=" +
          process.env.VUE_APP_API_KEY +
          "&lang=" +
          process.env.VUE_APP_LANG +
          "&api_token=" +
                 localStorage.getItem("token") +
          "&hotel_id=1&restaurant_id=1"
      )
      .then((res) => {
		  console.log(" ----------- Guest Info ---------");
		  console.log(res);
        this.guestData.guestName = res.data.data.first_name + ' ' + res.data.data.last_name
        this.guestData.guestRoom = res.data.data.more_data.room_number
        this.guestData.guestReservation = res.data.data.more_data.reservation
        this.guestData.guestArrivalData = res.data.data.more_data.checkin_date
        this.guestData.guestDepartureDate = res.data.data.more_data.checkout_date
		this.guestData.companions = res.data.data.more_data.companions
        this.isLoading = false;


 
      })
      .catch((error) => console.log(error));
	},
    getHotelData() {
      if (localStorage.getItem("token")) {
        this.axios
          .get(
            process.env.VUE_APP_API_BASE_URL +
              "api/hotel-restaurants?api_key=" +
              process.env.VUE_APP_API_KEY +
              "&lang=" +
              //process.env.VUE_APP_LANG +
              localStorage.getItem("localization") +
              "&api_token=" +
              localStorage.getItem("token") +
              "&hotel_id=1"
          )
          .then((res) => {
            this.hotelResturantsData = res.data.data;
            let ids = this.$route.params.menuId;
			this.restaurantID=ids.split("_")[0];
			this.bookingID=ids.split("_")[1];
            let obj = this.hotelResturantsData.find(
              (o) => o.restaurant_id == this.restaurantID
            );
            console.log(obj);
            this.resturantData.resturantProfileImage = this.baseUrl + obj.logo;
            this.resturantData.resturantName = obj.title;
            this.resturantData.resturantCategory = obj.name;
            this.isLoading = false;
          })
          .catch((error) => console.log(error));
      } else {
        this.axios
          .get(
            process.env.VUE_APP_API_BASE_URL +
              "api/hotel-restaurants?api_key=" +
              process.env.VUE_APP_API_KEY +
              "&lang=" +
              //process.env.VUE_APP_LANG +
              localStorage.getItem("localization") +
              "&hotel_id=1"
          )
          .then((res) => {
            this.hotelResturantsData = res.data.data;
            let id = this.$route.params.menuId;
            console.log(id);
            let obj = this.hotelResturantsData.find(
              (o) => o.restaurant_id == id
            );
            console.log(obj);
            this.resturantData.resturantProfileImage =
              this.baseUrl + obj.thumbnail_image;
            this.resturantData.resturantName = obj.title;
            this.resturantData.resturantCategory = obj.name;
            this.isLoading = false;
          })
          .catch((error) => console.log(error));
      }
    },
    getMenuData() {
      let id = this.$route.params.menuId;

      this.axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "api/restaurant-menu-items?api_key=" +
            process.env.VUE_APP_API_KEY +
            "&lang=" +
            //  process.env.VUE_APP_LANG +
            localStorage.getItem("localization") +
            "&api_token=" +
            localStorage.getItem("token") +
            "&hotel_id=1&restaurant_id=" +
            id
        )
        .then((res) => {
          var response = res.data;
          console.log(response);
          Object.keys(response).forEach((d, index) => {
            var category = {};
            var arr = d.split(":$$:");
            category["categoryName"] = arr[0];
            category["categoryIcon"] = this.baseUrl + arr[1];
            category["menuItems"] = response[d];
            category["id"] = index + 1;
            this.menus.push(category);
          });
          // this.resturantData.resturantProfileImage =
          //   this.baseUrl + res.data.data.logo;
          // this.resturantData.resturantName = res.data.data.title;
          // this.resturantData.resturantCategory = res.data.data.category.name;

          this.activeTabId = this.menus[0].id;
          if (this.menus.length < 5) {
            this.slideSize = this.menus.length;
          }
          if (window.innerWidth < 500) {
            this.slideSize = "1.5";
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    getCartData() {
      this.axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "api/order-data-not-checkout?api_key=" +
            process.env.VUE_APP_API_KEY +
            "&lang=" +
            //   process.env.VUE_APP_LANG +
            localStorage.getItem("localization") +
            "&api_token=" +
            localStorage.getItem("token") +
            // "8KxcnRyDoGwOBhjbjhrrx3vLuhUAoY3h28RO6indKBiBdKuL2IfZJ8bbrskX"+
            "&restaurant_id=" +
            this.$route.params.menuId.split("_")[0] +
            "&hotel_id=1"
        )
        .then((res) => {
          console.log(res);
          this.itemsInCart = [];
          this.categoeyItemsInCart = [];
		  this.categoeyItemsInCart=[{}];
          if (res.data.data) {
            console.log(res.data.data);
            this.cart = res.data.data;
            console.log(this.cart);
			
            this.cart.items.forEach((d, index) => {
              this.itemsInCart.push(d.item_id);
			  //Update the Categories Array;
			 let currentCatCount=this.getCurrentCategoryItemsCount(this.categoeyItemsInCart,d.category_id);
			let newCatCount;
              newCatCount=currentCatCount+1;
			  this.updateCurrentCategoryItemsCount(this.categoeyItemsInCart,d.category_id,newCatCount);
			  console.log(d.category_id+" current updated "+currentCatCount + "New Count " +newCatCount  );
			  //this.categoeyItemsInCart.push(d.category_id);
            });
          } else {
            console.log(this.cart);
            console.log(this.itemsInCart);
          }
          if (res.data.message == "Order not exist") {
            this.cart = { items: [] };
            console.log(this.itemsInCart);
          }
          console.log(res);
        })
        .catch((error) => console.log(error));
    },
    activeTab(cat) {
      this.activeTabId = cat.id;
    },
    addToCart(item) {
      if (!User.loggedIn()) {
        localStorage.setItem("redirected", this.$route.params.menuId);
        this.$router.push({ path: "" + "login" });
        return;
      }
    //  if (this.isMaxItemsforCategoryReached(this.categoeyItemsInCart,item.category_id,this.guestData.companions)) {
    //    console.log("isExist");
        document.getElementById("confirmation-modal").style.display = "block";
        $(".confirmbtn").data("id", item.id).attr("data-id", item.id); //setter
  //    } else {
   //     console.log("notExist");

        this.postToCart(item.id);
    //  }

      // console.log(item)
      // var cartItemData = {
      //   itemId: item.itemId,
      //   itemName: item.itemName,
      //   itemPrice: item.itemPrice,
      //   Qty: 1,
      // };
      // var isItemExist = false;

      // this.cart.cartItems.forEach((cartItem) => {
      //   if (cartItem.itemId == cartItemData.itemId) {
      //     isItemExist = true;
      //     cartItem.Qty += 1;
      //     cartItem.itemPrice = item.itemPrice * cartItem.Qty;
      //   }
      // });
      // if (!isItemExist) {
      //   this.cart.cartItems.push(cartItemData);
      // }

      // console.log(this.cart.cartItems)
    },
    confirmAdd() {
      var id = $(".confirmbtn").data("id"); //getter
      console.log("id", id);
      
      this.postToCart(id,"yes");
    },
    cancelConfirm() {
      document.getElementById("confirmation-modal").style.display = "none";
    },
    postToCart(id,extraChargeConfirmed="no") {
      document.getElementById("confirmation-modal").style.display = "none";
      this.isLoading = true;
      console.log("postToCart");
      var formData = {
        api_key: process.env.VUE_APP_API_KEY,
        //lang: process.env.VUE_APP_LANG,
        lang: localStorage.getItem("localization"),
        hotel_id: 1,
        api_token: localStorage.getItem("token"),
        restaurant_id: this.$route.params.menuId,
        item_id: id,
        confirmExtraCharge: extraChargeConfirmed,
      };

      this.axios
        .post(process.env.VUE_APP_API_BASE_URL + "api/add-order-item", formData)
        .then((res) => {
			console.log("==========================");
		console.log(res.data.status);
					console.log("==========================");

          if (res.data.status == "invalid") {
            localStorage.removeItem("token");
            this.$router.push({name:'GuestLogin'})
          }
		  else if(res.data.status === "WORNING"){
			  document.getElementById("confirmation-modal").style.display = "block";

		  }
          setTimeout(() => {}, 5000);
          this.getCartData();
			this.isLoading = false;
          console.log(res.data);
          // this.$notify({
          //   group: "foo",
          //   title: res.data.message,
          //   text: res.data.message,
          //   type: "success",
          // });
        })
        .catch((error) => {
          this.isLoading = false;

          this.errors = error;
          this.$notify({
            group: "foo",
            title: "Item Addition Error",
            text: "Something error in add to cart please check your inputs and try again ",
            type: "error",
          });
          console.log(error);
        });
    },
    clearCart(item) {
      this.isLoading = true;

      var formData = {
        api_key: process.env.VUE_APP_API_KEY,
        //lang: process.env.VUE_APP_LANG,
        lang: localStorage.getItem("localization"),
        api_token: localStorage.getItem("token"),
        order_id: this.cart.id,
      };

      // api_token:localStorage.getItem("token") ,

      this.axios
        .post(process.env.VUE_APP_API_BASE_URL + "api/clear-cart", formData)
        .then((res) => {
          this.isLoading = false;
          this.getCartData();
          console.log(formData);
          console.log(res.data);
          const index = this.itemsInCart.indexOf(item);
          if (index > -1) {
            this.itemsInCart.splice(index, 1); // 2nd parameter means remove one item only
          }
          // this.$notify({
          //   group: "foo",
          //   title: "Success",
          //   text: "cart cleared successfully ",
          //   type: "success",
          // });
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(formData);

          this.errors = error;
          this.$notify({
            group: "foo",
            title: "Item Removal Error",
            text: "Something error in remove from cart please check your inputs and try again ",
            type: "error",
          });
          console.log(error);
        });

      // console.log(this.cart.cartItems)
    },
    removeCart(item) {
      this.isLoading = true;

      var formData = {
        api_key: process.env.VUE_APP_API_KEY,
        //lang: process.env.VUE_APP_LANG,
        lang: localStorage.getItem("localization"),
        api_token: localStorage.getItem("token"),
        item_id: item,
        order_id: this.cart.id,
      };

      // api_token:localStorage.getItem("token") ,

      this.axios
        .post(
          process.env.VUE_APP_API_BASE_URL + "api/remove-order-item",
          formData
        )
        .then((res) => {
          this.isLoading = false;
          this.getCartData();
          console.log(formData);
          console.log(res.data);
          const index = this.itemsInCart.indexOf(item);
          console.log(this.itemsInCart, "this.itemsInCart");
          console.log(index);
          if (index > -1) {
            this.itemsInCart.splice(index, 1); // 2nd parameter means remove one item only
          }
          console.log(this.itemsInCart, "this.itemsInCartafter");
          // this.$notify({
          //   group: "foo",
          //   title: "Success",
          //   text: "item removed successfully ",
          //   type: "success",
          // });
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(formData);

          this.errors = error;
          this.$notify({
            group: "foo",
            title: "Item Removal Error",
            text: "Something error in remove from cart please check your inputs and try again ",
            type: "error",
          });
          console.log(error);
        });

      // console.log(this.cart.cartItems)
    },
    setLang() {
      this.axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "api/frontend-keywords?api_key=" +
            process.env.VUE_APP_API_KEY +
            "&lang=" +
            localStorage.getItem("localization")
        )
        .then((res) => {
          console.log(res);
          this.lang = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    // a computed getter
    subTotal: function () {
      var total = 0;
      // Here will be a check if at least 4 characters in input field, if yes
      this.cart.items.forEach((cartItem) => {
        total += cartItem.itemPrice;
      });
      return total;
    },
    grandTotal: function () {
      return this.subTotal + this.subTotal * (this.cart.tax / 100);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
<style>
#plus-table-id-a10ca45 th,
#plus-table-id-a10ca45 th .plus-table__text {
  margin: 0 auto;
  text-align: left;
  margin-left: 0;
}
</style>
<style>
.elementor-1520
  .elementor-element.elementor-element-64d82d2
  > .elementor-container {
  max-width: 850px;
  min-height: 347px;
}
.elementor-1520
  .elementor-element.elementor-element-64d82d2
  > .elementor-container
  > .elementor-column
  > .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.elementor-1520 .elementor-element.elementor-element-64d82d2 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 1rem 1rem 1rem 1rem;
}
.elementor-1520
  .elementor-element.elementor-element-64d82d2
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-1520 .elementor-element.elementor-element-5f364aa {
  width: 100%;
}
.elementor-bc-flex-widget
  .elementor-1520
  .elementor-element.elementor-element-5f364aa.elementor-column
  .elementor-widget-wrap {
  align-items: center;
}
.elementor-1520
  .elementor-element.elementor-element-5f364aa.elementor-column.elementor-element[data-element_type="column"]
  > .elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center;
}
.elementor-1520
  .elementor-element.elementor-element-5f364aa.elementor-column
  > .elementor-widget-wrap {
  justify-content: space-evenly;
}
.elementor-1520
  .elementor-element.elementor-element-5f364aa
  > .elementor-widget-wrap
  > .elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
  margin-bottom: 40px;
}
.elementor-1520 .elementor-element.elementor-element-41c4a22 {
  width: auto;
  max-width: auto;
  align-self: center;
}
.elementor-1520 .elementor-element.elementor-element-17a164e {
  width: auto;
  max-width: auto;
  align-self: center;
}
.elementor-1520 .elementor-element.elementor-element-a5ab252 {
  width: auto;
  max-width: auto;
  align-self: center;
}
.elementor-1520 .elementor-element.elementor-element-668a653 {
  width: auto;
  max-width: auto;
  align-self: center;
}
.swiperStyle2 {
  background-color: #4e4e4e;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #f0f0f0;
}
.swiperStyle {
  background-color: #f0f0f0;
  color: #4e4e4e;
}
.spanStyle {
  font-weight: bold;
  margin: 10px;
  font-size: 1.2rem;
  font-family: "Roboto";
}
.elementor-1520 .elementor-element.elementor-element-5ae5bf7 {
  width: auto;
  max-width: auto;
  align-self: center;
}
.elementor-1520 .elementor-element.elementor-element-94b81b0 {
  width: auto;
  max-width: auto;
  align-self: center;
}
.elementor-1520 .elementor-element.elementor-element-380722b {
  width: auto;
  max-width: auto;
  align-self: center;
}
.elementor-1520 .elementor-element.elementor-element-86311d8 {
  width: auto;
  max-width: auto;
  align-self: center;
}
.elementor-widget .tippy-tooltip .tippy-content {
  text-align: center;
} /* Start custom CSS for template, class: .elementor-element-41c4a22 */
.cardMaxWidth {
  max-height: 320px;
  max-width: 300px;
} /* End custom CSS */ /* Start custom CSS for template, class: .elementor-element-17a164e */
.cardMaxWidth {
  max-height: 320px;
  max-width: 300px;
} /* End custom CSS */ /* Start custom CSS for template, class: .elementor-element-a5ab252 */
.cardMaxWidth {
  max-height: 320px;
  max-width: 300px;
} /* End custom CSS */ /* Start custom CSS for template, class: .elementor-element-668a653 */
.cardMaxWidth {
  max-height: 320px;
  max-width: 300px;
} /* End custom CSS */ /* Start custom CSS for template, class: .elementor-element-5ae5bf7 */
.cardMaxWidth {
  max-height: 320px;
  max-width: 300px;
} /* End custom CSS */ /* Start custom CSS for template, class: .elementor-element-94b81b0 */
.cardMaxWidth {
  max-height: 320px;
  max-width: 300px;
} /* End custom CSS */ /* Start custom CSS for template, class: .elementor-element-380722b */
.cardMaxWidth {
  max-height: 320px;
  max-width: 300px;
} /* End custom CSS */ /* Start custom CSS for template, class: .elementor-element-86311d8 */
.cardMaxWidth {
  max-height: 320px;
  max-width: 300px;
} /* End custom CSS */ /* Start custom CSS for section, class: .elementor-element-64d82d2 */
.elementor-1520 .elementor-element.elementor-element-64d82d2 {
  max-height: 62vh;
  overflow-x: hidden;
  overflow-y: scroll;
}
.elementor-1520
  .elementor-element.elementor-element-64d82d2::-webkit-scrollbar {
  width: 5px;
}
.elementor-1520
  .elementor-element.elementor-element-64d82d2::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}
.elementor-1520
  .elementor-element.elementor-element-64d82d2::-webkit-scrollbar-thumb {
  background: var(--e-global-color-primary);
}
.bdt-clear-buttton {
  background-color: var(--e-global-color-3669d80);
  padding: 10px 20px;
}
.no-discount {
  background-color: #ffcc6d;
}
.cancelbtn,
.confirmbtn {
  width: 150px;
  background-color: #04aa6d;
  color: white;
  padding: 14px 20px;
  margin: 8px 10px;
  border: none;
  cursor: pointer;
  opacity: 0.9;
}

.cancelbtn:hover,
.confirmbtn:hover {
  opacity: 1;
}

/* Add a color to the cancel button */
.cancelbtn {
  background-color: #ccc;
  color: black;
}

/* Add a color to the confirm button */
.confirmbtn {
  background-color: #efab2f;
}

/* Add padding and center-align text to the container */
.container {
  padding: 16px;
  text-align: center;
}
.container p{
      font-size: 17px;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 5; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: #000000b5;
  padding-top: 50px;
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 5% auto 15% auto; /* 5% from the top, 15% from the bottom and centered */
  border: 1px solid #888;
  width: 500px; /* Could be more or less, depending on screen size */
}

/* Style the horizontal ruler */
hr {
  border: 1px solid #f1f1f1;
  margin-bottom: 25px;
}
.confirm-title{
  color:#7f7460;
}
/* Clear floats */
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

/* Change styles for cancel button and confirm button on extra small screens */
@media screen and (max-width: 300px) {
  .cancelbtn,
  .confirmbtn {
    width: 100%;
  }
}
/* End custom CSS */ /* Start custom CSS */
.body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.body #body {
  width: 100%;
  height: 100%;
  overflow: hidden;
} /* End custom CSS */
</style>

<!-- eslint-enable -->
